import { faMapMarkerAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

const Contact = () => {
  return (
    <section className="bg-FOTO_CONTACTO bg-cover bg-fixed">
      <h1 className="title-font text-white">CONTACTO</h1>
      <footer
        id="contacto"
        className="py-12 grid justify-items-center  gap-1 px-5 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 overflow-hidden"
      >
        <form
          className="w-full max-w-lg shadow-4xl"
          name="contact"
          method="post"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full  px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-white text-xs font-bold mb-2 text-4xl"
                htmlFor="name"
              >
                Nombre
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name"
                name="name"
                type="text"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-white text-xs font-bold mb-2 text-4xl"
                htmlFor="correo"
              >
                Correo
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                name="email"
                id="email"
                type="email"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-white text-xs font-bold mb-2 text-4xl"
                htmlFor="correo"
              >
                Mensaje
              </label>
              <textarea
                className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                name="message"
              ></textarea>
            </div>
          </div>
          <div className="md:flex md:items-center">
            <input
              className="shadow bg-red-700 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded w-full text-4xl"
              type="submit"
              value="Enviar"
            />
          </div>
        </form>
        <div id="map" className="map__container">
          <MapContainer
            center={[-34.8765458, -56.1760831]}
            zoom={15}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[-34.8765458, -56.1760831]} />
          </MapContainer>
        </div>
        <div></div>
        <div className="text-white text-3xl font-bold grid">
          <span>
            <FontAwesomeIcon icon={faMobileAlt} /> 092 508 998
          </span>

          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Yaguari 1963
          </span>
        </div>
      </footer>
      <span className="p-5 bg-gray-900 flex justify-center gap-5 text-5xl text-white col-span-2 ">
        <a href="https://www.facebook.com/ElAcordeStudio/">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://www.instagram.com/elacordestudio/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://twitter.com/ElAcordeStudio">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </span>
    </section>
  );
};

export default Contact;
