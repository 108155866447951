import React from "react";
import "./App.css";
import Logo from "./imgs/el_acorde.png";
import Contact from "./Components/Contact";
import Nav from "./Components/Nav";

function App() {
  return (
    <>
      <div className="fixed bottom-3 right-3 z-1000 test">
        <a
          href="https://api.whatsapp.com/send?phone=59892508998"
          type="button"
          target="_blank"
          rel="noreferrer"
          className="bg-[#075e54] hover:bg-[#075e54]/90 focus:ring-4 focus:outline-none font-medium rounded-lg text-md px-5 py-2.5 text-center grid grid-flow-col place-items-center gap-2 mr-2 mb-2 rounded-full px-0 py-0 drop-shadow-lg"
        >
          <svg
            height="70"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 175.216 175.552"
          >
            <defs>
              <linearGradient
                id="b"
                x1="85.915"
                x2="86.535"
                y1="32.567"
                y2="137.092"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#57d163"></stop>
                <stop offset="1" stop-color="#23b33a"></stop>
              </linearGradient>
              <filter
                id="a"
                width="1.115"
                height="1.114"
                x="-.057"
                y="-.057"
                color-interpolation-filters="sRGB"
              >
                <feGaussianBlur stdDeviation="3.531"></feGaussianBlur>
              </filter>
            </defs>
            <path
              fill="#b3b3b3"
              d="m54.532 138.45 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.523h.023c33.707 0 61.139-27.426 61.153-61.135.006-16.335-6.349-31.696-17.895-43.251A60.75 60.75 0 0 0 87.94 25.983c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.558zm-40.811 23.544L24.16 123.88c-6.438-11.154-9.825-23.808-9.821-36.772.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954zm0 0"
              filter="url(#a)"
            ></path>
            <path
              fill="#fff"
              d="m12.966 161.238 10.439-38.114a73.42 73.42 0 0 1-9.821-36.772c.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954z"
            ></path>
            <path
              fill="url(#linearGradient1780)"
              d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"
            ></path>
            <path
              fill="url(#b)"
              d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"
            ></path>
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"
            ></path>
          </svg>
        </a>
      </div>
      <div className="App bg-black">
        <Nav />
        <header className="min-h-screen grid items-end justify-items-center relative overflow-hidden">
          <div className="absolute w-full bg-center bg-header bg-fixed card-blur-image header-animation"></div>
          <img id="logo" src={Logo} alt="" className="object-center z-10 p-5" />
        </header>

        <section
          id="sala"
          className="min-h-screen grid justify-center content-center gap-10 bg-SALA bg-cover bg-fixed font-bold text-white px-20 font-roboto section-font py-10"
        >
          <h1 className="title-font">SALA</h1>
          <p>
            El Acorde Multimedia Studio surge de la necesidad de proporcionar al
            artista y/o al emprendedor un espacio y servicio integral donde
            pueda realizar sus contenidos de alta calidad multimedia de forma
            práctica y accesible. Desde el año 2002 generando experiencia con
            clientes y amigos satisfechos en un constante crecimiento para poder
            posicionarnos como una muy buena opción para realizar todo tipo de
            proyectos audiovisuales.
          </p>
          <p>
            Somos la opción más completa para las mejoras en tus contenidos y
            producciones que andas buscando.
          </p>
        </section>

        <section
          id="ensayos"
          className="min-h-screen grid justify-center content-center gap-10 bg-cover bg-fixed font-bold text-white px-20 font-roboto card-blur section-font py-10"
        >
          <div className="absolute w-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-fixed bg-sala_1 card-blur-image"></div>
          <h1 className="title-font">ENSAYOS</h1>
          <h2>SOLO TIENES QUE TOCAR</h2>
          <p>
            Te invitamos a conocer nuestras dos salas de ensayo perfectamente
            acondicionadas para que tengas tu espacio y así disfrutar de tu
            música sin tener que preocuparte por nada.
          </p>
          <p>
            Cada una de nuestras salas cuenta con aire acondicionado, con una
            acústica buscada y equipadas con las marcas más reconocidas en
            amplificadores en el mercado.
          </p>

          <p>El predio también cuenta con:</p>
          <ul
            className="list-disc"
            style={{ textAlign: "initial", margin: "auto" }}
          >
            <li>Estacionamiento gratuito.</li>
            <li>Cámaras de seguridad.</li>
            <li>Zona en común al aire libre para descansar.</li>
            <li>Área de fumadores.</li>
          </ul>
        </section>

        <section
          id="escuelas"
          className="min-h-screen grid justify-center content-center gap-10 bg-FOTO_ESCUELAS bg-cover bg-fixed font-bold text-white font-roboto card-zoom section-font py-10"
        >
          <div className="absolute w-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-fixed bg-FOTO_ESCUELAS card-zoom-image"></div>
          <h1 className="title-font">ESCUELAS</h1>
          <p>
            Contamos con un servicio exclusivo y único en el país dedicado al
            mantenimiento y soluciones en audio y video para escuelas, colegios
            e iglesias.
          </p>
          <p>
            Generamos las mejoras deseadas para recintos particulares como:
            Salas, aulas e iglesias tanto en optimizar acústica, como en una
            correcta ejecución de sus equipos para optimizar sus propuestas.
            <br />
            (Asesoramiento, puesta a punto, mejoras y trabajos afines)
          </p>

          <p>Accede a las mejoras y contenidos que estas necesitando:</p>
          <ul>
            <li>Nos dedicamos al Audio y video.</li>
          </ul>
          <p>
            Nuestro equipo analiza la combinación perfecta entre herramientas y
            conocimiento para optimizar sus procesos tecnológicos y así estar a
            top en cuanto a las necesidades del mundo actual.
          </p>

          <p>
            Te ayudamos con la mejor combinación de equipos de audio y video
            para mejorar la producción de eventos y en su uso cotidiano para
            sacarle el mejor provecho con su correcta configuración. Ya sea con
            sus equipos propios o con equipos de alquiler.
          </p>

          <p>
            Asegura que tus eventos sean los mejores y da que hablar con una
            experiencia audiovisual unica.
          </p>

          <p>
            Somos la opción más completa para las mejoras en tus producciones
            que andas buscando.
          </p>
        </section>

        <section
          id="asesoramiento"
          className="min-h-screen grid justify-center content-center gap-10 bg-CONTROL bg-cover bg-fixed font-bold text-white px-20 font-roboto section-font py-10"
        >
          <h1 className="title-font">ASESORAMIENTO</h1>
          <p>
            En el Acorde Sala te brindamos el asesoramiento personalizado que
            necesitas ya sea para todo tipo de proyectos audiovisuales, tanto de
            artistas, autores y bandas. Así como también para potenciar
            emprendimientos de productos y servicios.
          </p>
          <p>
            Nuestro servicio orienta a los clientes y usuarios en qué pasos dar
            para una óptima mejora en sus contenidos de calidad, evaluando y
            recomendando que herramientas y procesos sean necesarios para llegar
            a los mejores resultados en alta calidad para sus emprendimientos y
            necesidades.
          </p>
          <p>
            Creamos las soluciones en base a las necesidades del cliente, se
            hace una evaluación del proyecto para conseguir los mejores
            resultados y se estima el tiempo que puede tomar el proyecto a
            realizar.
          </p>
        </section>

        <section
          id="audiovisual"
          className="min-h-screen grid justify-center content-center gap-10 bg-AUDIOVISUAL bg-cover bg-fixed font-bold text-white px-20 font-roboto section-font py-10"
        >
          <h1 className="title-font">AUDIOVISUAL</h1>
          <p>
            En el Acorde Sala tenemos la solución a tu medida con lo último en
            producción multimedia para que vos cuentes con el lugar y las
            herramientas para tus producciones y contenidos de alta calidad.
          </p>
          <p>
            Contamos con experiencia en soporte integral para artistas, bandas y
            trabajos multimedia en general, tanto en video, como cortos y
            también generamos producciones de contenido para redes como
            fotografía, producción de canciones, singles, discos y videos.
          </p>
          <p>
            Somos la opción más completa para las mejoras en tus contenidos y
            producciones que andas buscando.
          </p>
        </section>
        <Contact />
      </div>
    </>
  );
}

export default App;
